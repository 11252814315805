import { apiUrl, routePrefix, _axios_base_get_list } from "..";

const PAGESIZE = 30;

export default function getProfileSettingsChangeLogs(
  limit = PAGESIZE,
  skip = 0,
  query = {}
) {
  return _axios_base_get_list(
    `${apiUrl}/robot/getProfileSettingsChangeLogs`,
    limit,
    skip,
    query
  );
}
