import { Skeleton, Spin, Table, Tabs, List } from "antd";
import { navigate } from "gatsby-link";
import { get, isEmpty, map, toLower } from "lodash";
import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getProfiles from "../../../newApi/profile/getProfiles";
import getHighFrequencyRobots from "../../../newApi/robots/getHighFrequencyRobots";
import { updateActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import {
  formatDecimalNumber,
  isValidNumber,
} from "../../../utilities/common-function";
import { numberDecimal } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import getHighFrequencyOrders from "../../../newApi/robots/getHighFrequencyOrders";
import { formatAmount } from "../../../utilities/common-function";
import { getFormatedDate } from "../config";
import PastProfileSettingsPage from "../components/PastProfileSettingsPage";
import { robotStrategyDataKey } from "../../../utilities/data";

const PAGE_SIZE = 10;

const PastITSRecordPage = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [selectedTab, setselectedTab] = useState("");
  const [highFrequencyRobotData, setHighFrequencyRobotData] = useState([]);
  const [robotTotal, setRobotTotal] = useState(0);
  const [robotPage, setRobotPage] = useState(1);
  const [highFrequencyOrderData, setHighFrequencyOrderData] = useState([]);
  const [highFrequencyOrderTotal, setHighFrequencyOrderTotal] = useState(0);
  const [highFrequencyOrderPage, setHighFrequencyOrderPage] = useState(1);

  useEffect(() => {
    getProfileAPI();
  }, []);

  useEffect(() => {
    setselectedTab(props.selectedTab);
  }, [props.selectedTab]);

  useEffect(() => {
    if (!isEmpty(profile)) {
      getHighFrequencyRobotData(0);
      getHighFrequencyOrderData(0);
    }
  }, [profile]);

  useEffect(() => {
    getHighFrequencyOrderData((highFrequencyOrderPage - 1) * PAGE_SIZE);
  }, [highFrequencyOrderPage]);

  useEffect(() => {
    getHighFrequencyRobotData((robotPage - 1) * PAGE_SIZE);
  }, [robotPage]);

  function getProfileAPI() {
    if (get(props.user, "user.profile._id")) {
      getProfiles(1, 0, {
        _id: get(props.user, "user.profile._id"),
      }).then((res) => {
        setProfile(get(res, "data[0]"));
      });
    }
  }

  function getHighFrequencyRobotData(skip) {
    setLoading(true);
    let robotProfilesData = get(profile, "robotProfiles");
    robotProfilesData = map(robotProfilesData, (item, index) => {
      return get(item, "robotProfileId");
    });
    if (!isEmpty(profile) && !isEmpty(robotProfilesData)) {
      getHighFrequencyRobots({
        profileIdIn: robotProfilesData,
        profitTypeNe: 1,
        liquidationne: 0,
        limit: PAGE_SIZE,
        skip,
        sort: {
          liquidDate: -1,
        },
      })
        .then((res) => {
          // console.log(res);
          setHighFrequencyRobotData(get(res, "data"));
          setRobotTotal(res?.total || 0);
          setLoading(false);
        })
        .catch((err) => {
          //console.log(err);
          setLoading(false);
        });
    }
    setLoading(false);
  }

  function getHighFrequencyOrderData(skip) {
    if (!isEmpty(profile)) {
      setLoading(true);
      let robotProfiles = get(profile, "robotProfiles");

      if (!isEmpty(robotProfiles)) {
        robotProfiles = map(robotProfiles, (item, index) => {
          return get(item, "robotProfileId");
        });

        // console.log(robotProfiles);

        getHighFrequencyOrders({
          limit: PAGE_SIZE,
          skip: skip,
          profitTypeNe: 1,
          profileIdIn: robotProfiles,
          populate: [
            {
              service: "robots",
              foreignId: "robotId",
            },
          ],
        })
          .then((res) => {
            // console.log("res", res);
            let temp_data = get(res, "data");
            if (!isEmpty(temp_data)) {
              setHighFrequencyOrderData(temp_data);
              setHighFrequencyOrderTotal(get(res, "total"));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }

  const highFrequencyOrderContent = {
    dataSource: highFrequencyOrderData || [],
    renderItem: (i) => {
      const populatedRobot = get(i, "populatedRobotId");
      const coinMarketStructure = get(populatedRobot, "coinMarketStructure");
      const base = get(coinMarketStructure, "base");

      return (
        <React.Fragment>
          <div className="flex justify-between items-center text-black">
            <span className="inline-block w-1/2 mr-2">
              <div className="flex items-center text-xs">
                <span
                  className={`inline-block mr-2 ${
                    get(i, "side")
                      ? toLower(get(i, "side")) === "buy"
                        ? "text-green-500"
                        : toLower(get(i, "side")) === "sell"
                        ? "text-red-500"
                        : ""
                      : ""
                  }`}
                >
                  {get(i, "side")
                    ? toLower(get(i, "side")) === "buy"
                      ? t("buy", sourceKey.highFrequency)
                      : toLower(get(i, "side")) === "sell"
                      ? t("sell", sourceKey.highFrequency)
                      : ""
                    : ""}
                </span>
                <span className="inline-block ">{get(i, "symbol")}</span>
              </div>
              <div className="flex text-black">
                <div className="inline-block w-1/7 text-sm">
                  <span>{base}</span>{" "}
                </div>
                <div className="inline-block mx-2">
                  {/* <span style={{ fontSize: "0.6rem" }}>
                    ID:{" "}
                    {get(i, "robotId")?.substring(get(i, "robotId").length - 4)}{" "}
                  </span> */}
                </div>
              </div>
              <div className="flex items-center text-xs">
                {t("orderNo", sourceKey.highFrequency)} :{" "}
                {get(i, "_id")?.substring(get(i, "_id").length - 6) || "N/A"}
              </div>
              <div className="flex items-center text-xs">
                {get(i, "timestamp")
                  ? getFormatedDate(get(i, "timestamp"), true)
                  : ""}
              </div>
            </span>
            <span className="inline-block w-1/2 mt-5">
              <div className="flex items-center text-xs ">
                <span className={`inline-block sm:w-1/6 w-1/4`}>
                  {/* 交易金额 Transacted Price */}
                  {t("transactionPrice", sourceKey.highFrequency)}
                </span>
                <span className="inline-block mx-2">:</span>
                <span className="inline-block  w-1/2 ">
                  {formatAmount(get(i, "price") || 0)}
                </span>
              </div>
              <div className="flex items-start text-xs ">
                <span className={`inline-block sm:w-1/6 w-1/4  `}>
                  {/* 金额 Total */}
                  {t("total", sourceKey.highFrequency)}
                </span>
                <span className="inline-block mx-2">:</span>
                <span className="inline-block w-1/2 ">
                  {formatAmount(get(i, "cost") || 0)}
                </span>
              </div>
              {i?.side === "sell" && (
                <div className="flex items-start text-xs ">
                  <span
                    className={`inline-block sm:w-1/6 w-1/4 flex justify-start items-center `}
                  >
                    {t("pnl", sourceKey.highFrequency)}
                  </span>
                  <span className="inline-block mx-2 flex justify-start items-center ">
                    :
                  </span>
                  <div className="inline-block w-1/2 ">
                    <span>
                      {formatAmount(get(i, "profit") || 0)}{" "}
                      <span
                        className=""
                        style={{
                          fontSize: "8px",
                        }}
                      >
                        USDT
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </span>
          </div>
        </React.Fragment>
      );
    },
    totalPages: highFrequencyOrderTotal,
    currentPage: highFrequencyOrderPage,
    onChangePage: (highFrequencyOrderPage) => {
      setHighFrequencyOrderPage(highFrequencyOrderPage);
    },
  };

  // const _robotStatusClass = (robot) => {
  //   return indexOf(["1", "2", "3"], get(robot, "status")) !== "-1"
  //     ? "rounded-md bg-red-500 text-white"
  //     : get(robot, "isPreStop") === 1
  //     ? "rounded-md bg-main-color-gradient"
  //     : "";
  // };

  function getLiquidReason(type) {
    switch (type) {
      case 1:
        return t("manualLiquid", sourceKey.highFrequency);
      case 0:
        return t("noActiveDay", sourceKey.highFrequency);
      case 3:
        return t("maxpriceClosed", sourceKey.highFrequency);
      case 2:
        return t("preStopAll", sourceKey.highFrequency);
      case 4:
        return t("averagePriceLiquid", sourceKey.highFrequency);
      case 5:
        return t("unsupportPair", sourceKey.highFrequency);
      default:
        return "-";
    }
    // if (type === 0) {
    //   return "手动平仓 Manual Liquid";
    // } else if (type === 1) {
    //   return "激活天数不足 Insufficient Active Day";
    // } else {
    //   return "-";
    // }
  }

  const _renderTable = () => {
    let columns = [
      // {
      //   title: (
      //     <React.Fragment>
      //       <div className="text-sm  text-center">
      //         策略设定 <br />
      //         Robot Type
      //       </div>
      //     </React.Fragment>
      //   ),
      //   dataIndex: "originConfig",
      //   key: "originConfig",
      //   render: (value, record) => {
      //     return (
      //       <div className="text-sm text-center">{getRobotType(record)}</div>
      //     );
      //   },
      // },
      {
        title: (
          <React.Fragment>
            <div className="text-sm  text-center">
              {/* 币种 <br /> */}
              {t("coinPair", sourceKey.highFrequency)}
            </div>
          </React.Fragment>
        ),
        dataIndex: "symbol",
        key: "symbol",
        fixed: "left",
        render: (value, record) => {
          return {
            props: {},
            children: <div className={`text-sm text-center `}>{value}</div>,
          };
        },
      },
      {
        title: (
          <React.Fragment>
            <div className="text-sm  text-center">
              {/* 关闭原因 <br /> */}
              {t("closeType", sourceKey.highFrequency)}
            </div>
          </React.Fragment>
        ),
        dataIndex: "liquidType",
        key: "liquidType",
        render: (value, record) => {
          // value = find(currentOrders, ['symbol', get(record, 'symbol')]);
          return (
            <div className="text-sm text-center">
              {/* {getBoughtBinanceStocks(get(record, 'binanceStocks') || []).length || 0} */}
              {getLiquidReason(get(record, "liquidation"))}
            </div>
          );
        },
      },
      {
        title: (
          <React.Fragment>
            <div className="text-sm  text-center">
              {/* 关闭日期 <br /> */}
              {t("pnl", sourceKey.highFrequency)}
            </div>
          </React.Fragment>
        ),
        dataIndex: "profitEarned",
        key: "profitEarned",
        render: (value, record) => {
          return (
            <div
              className={`text-sm text-center ${
                isValidNumber(parseFloat(value))
                  ? parseFloat(value) > 0
                    ? "text-green-500"
                    : parseFloat(value) < 0
                    ? "text-red-500"
                    : ""
                  : ""
              }`}
            >
              {formatDecimalNumber(parseFloat(value || 0), numberDecimal)}
            </div>
          );
        },
      },
      {
        title: (
          <React.Fragment>
            <div className="text-sm  text-center">
              {/* 关闭日期 <br /> */}
              {t("closeDate", sourceKey.highFrequency)}
            </div>
          </React.Fragment>
        ),
        dataIndex: "liquidDate",
        key: "liquidDate",
        render: (value, record) => {
          return (
            <div className={`text-sm text-center `}>
              {get(record, "liquidDate")
                ? getFormatedDate(get(record, "liquidDate"), true)
                : "-"}
            </div>
          );
        },
      },
    ];

    return (
      <Table
        className="rounded-2xl box-bg-color m-4 no-row-hover"
        size={"small"}
        columns={columns}
        pagination={{
          simple: true,
          total: robotTotal,
          current: robotPage,
          onChange: (page_selected) => {
            // console.log(page_selected);
            setRobotPage(page_selected);
          },
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
        }}
        //className="no-row-hover"
        dataSource={highFrequencyRobotData}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              props.updateActiveId(get(record, "_id"));
              navigate(routes.highFrequencyDetails.to());
              // switch (key) {
              //   case 1:
              //     props.updateActiveId(get(record, "_id"));
              //     navigate(routes.futureDetails.to());
              //     break;
              //   case 2:
              //     props.updateActiveId(get(record, "_id"));
              //     navigate(routes.highFrequencyDetails.to());
              //     break;
              //   case 3:
              //     props.updateActiveId(get(record, "_id"));
              //     navigate(routes.safetyPinDetails.to());
              //     break;
              //   case 4:
              //     props.updateActiveId(get(record, "_id"));
              //     navigate(routes.chaseDetails.to());
              //     break;

              //   default:
              //     break;
              // }
            }, // click row
          };
        }}
      ></Table>
    );
  };

  const tabItems = [
    {
      title: t("transaction", sourceKey.highFrequency),
      titleCn: "交易记录",
      value: "transactions",
      renderItem: () => {
        return (
          <div className="px-4" style={{ minHeight: "140px" }}>
            <List
              dataSource={highFrequencyOrderContent.dataSource || []}
              itemLayout="vertical"
              pagination={{
                simple: true,
                pageSize: PAGE_SIZE,
                showSizeChanger: false,
                total: highFrequencyOrderContent.totalPages
                  ? highFrequencyOrderContent.totalPages
                  : highFrequencyOrderContent.dataSource.length || 0,
                onChange:
                  typeof highFrequencyOrderContent.onChangePage === "function"
                    ? highFrequencyOrderContent.onChangePage
                    : null,
              }}
              renderItem={(v, index) => {
                return (
                  <List.Item key={`${index}`}>
                    {typeof highFrequencyOrderContent.renderItem === "function"
                      ? highFrequencyOrderContent.renderItem(v, index)
                      : null}
                  </List.Item>
                );
              }}
            ></List>
          </div>
        );
      },
    },
    {
      title: t("liquidationRecord", sourceKey.highFrequency),
      titleCn: "平倉記錄",
      value: "liquidationRecord",
      renderItem: () => {
        return _renderTable();
      },
    },
    {
      title: t("positionSettingLogs", sourceKey.highFrequency),
      value: "positionSettingLogs",
      renderItem: () => {
        return (
          <PastProfileSettingsPage
            profile={profile}
            strategy={robotStrategyDataKey.usdt}
          />
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack={true}
            suffix={
              <div className="justify-end flex items-center">
                {/* 机器人历史记录{" "}
                  <MediaQuery maxWidth={440}>
                    <br />
                  </MediaQuery> */}
                {t("pastRobotRecord", sourceKey.highFrequency)}
              </div>
            }
          >
            <Tabs
              activeKey={selectedTab}
              onChange={(e) => {
                setselectedTab(e);
              }}
              centered
              style={{ color: "black" }}
            >
              {map(tabItems, (item) => {
                return (
                  <Tabs.TabPane
                    tab={
                      <div
                        className="text-center"
                        style={{ "white-space": "pre-line" }}
                      >
                        {/* {get(item, "titleCn")}
                                    <br /> */}
                        {get(item, "title")}
                      </div>
                    }
                    key={get(item, "value")}
                  >
                    {item.renderItem()}
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(PastITSRecordPage);
