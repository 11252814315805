import { Button, List, Spin } from "antd";
import { find, isEmpty, map } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";

import { useTranslation } from "../../../locales/useTranslation";
import getHighFrequencyOrders from "../../../newApi/robots/getHighFrequencyOrders";
import getProfileSettingsChangeLogs from "../../../newApi/robots/getProfileSettingsChangeLogs";
import { triggerModal } from "../../../redux/actions/app-actions";
import { updateDepositActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { platformLogo } from "../../../utilities/data";
import { AllExchangePlatforms } from "../../../utilities/startUp";
import { getFormatedDate } from "../config";

// markup
const PAGE_SIZE = 10;
const HighFrequencyInfoDrawer = (props) => {
  // const accessKey = get(props.user, "accessKey");
  const { t } = useTranslation();
  // const location = useLocation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  // const [transactions, setTransactions] = useState([]);
  const [info, setInfo] = useState({});
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [platform, setPlatform] = useState("tokocrypto");

  const profile = props.profile || {};
  const strategy = props.strategy || "";

  useEffect(() => {
    if (!isEmpty(profile)) {
      getData(0);
    }
  }, [props.profile]);

  useEffect(() => {
    getData((page - 1) * PAGE_SIZE);
  }, [page, platform]);

  function getData(skip) {
    if (!isEmpty(profile)) {
      setLoading(true);

      let robotProfilesData = get(profile, "robotProfiles") || [];
      let robotProfile = find(robotProfilesData, (item, index) => {
        return item?.exchange === platform;
      });

      if (isEmpty(robotProfile)) {
        return;
      }

      getProfileSettingsChangeLogs(PAGE_SIZE, skip, {
        profileId: robotProfile?.robotProfileId,
        exchange: platform,
        strategy: strategy,
      })
        .then((res) => {
          // console.log("res", res);
          let temp_data = get(res, "data");
          if (!isEmpty(temp_data)) {
            setDataSource(temp_data);
            setTotal(get(res, "total"));
          }
          setLoading(false);
        })
        .catch((err) => {
          //console.log(err);
          setLoading(false);
        });
    }
  }

  function preStopTitle(item) {
    let value = get(item, `settings.${strategy}.preStop`);
    let title = "";

    switch (value) {
      case 0:
        title = t("off");
        break;
      case 1:
        title = t("tempSell", sourceKey.highFrequency);
        break;
      case 2:
        title = t("stopBuy", sourceKey.highFrequency);
        break;
      default:
        break;
    }
    return title;
  }

  const _renderItem = (item, index) => {
    return (
      <div className=" mx-2" key={index}>
        <div className="flex my-1">
          <span className="inline-block w-1/4">
            {t("preStop", sourceKey.highFrequency)}
          </span>
          <span className="inline-block mx-1">:</span>
          <span
            className={`inline-block w-1/2 ml-2 font-bold ${
              get(item, `settings.${strategy}.preStop`) === 0
                ? "text-red-400"
                : "text-green-400"
            }`}
          >
            {preStopTitle(item)}
          </span>
        </div>
        <div className="flex my-1">
          <span className="inline-block w-1/4">
            {t("changeTime", sourceKey.highFrequency)}
          </span>
          <span className="inline-block mx-1">:</span>
          <span className="inline-block w-1/2 ml-2">
            {getFormatedDate(item?.createdAt, true)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <div className="ml-auto flex justify-between">
          <div className="ml-2">
            {/* {t("platformSelect", sourceKey.highFrequency)} : */}
          </div>
          <div>
            {map(AllExchangePlatforms, (item, index) => {
              return (
                <Button
                  style={{ verticalAlign: "middle" }}
                  className={`mx-2 ${
                    platform === item ? "second-button-color" : ""
                  }`}
                  onClick={() => {
                    setPlatform(item);
                  }}
                >
                  {/* {t(item, sourceKey.highFrequency)} */}
                  <img src={get(platformLogo, item)} width={20} height={20} />
                </Button>
              );
            })}
          </div>
        </div>
        <List
          dataSource={dataSource}
          itemLayout="vertical"
          pagination={{
            simple: true,
            pageSize: PAGE_SIZE,
            showSizeChanger: false,
            total: total,
            onChange: (page) => {
              setPage(page);
            },
          }}
          renderItem={(item, index) => {
            return (
              <List.Item key={`${index}`}>{_renderItem(item, index)}</List.Item>
            );
          }}
        ></List>
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
  updateDepositActiveId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HighFrequencyInfoDrawer);
