import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import LiquidizedRobotList from "../components/robot/pages/LiquidizedRobotList"


// markup
const LiquidizedRobotListPage = () => {
    return (
        <React.Fragment>
            <SharedHeader />
            <LiquidizedRobotList />
        </React.Fragment>
    )
}

export default LiquidizedRobotListPage
